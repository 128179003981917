// Initialize Custom Select with Icons.
document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.customSelect');

    selects.forEach(select => {
        const choicesData = [];

        // Extract options and store data manually.
        Array.from(select.options).forEach(option => {
            if (option.value) {
                choicesData.push({
                    value: option.value,
                    label: option.dataset.icon
                        ? `<img src="${option.dataset.icon}" alt="" class="choice-icon" />${option.textContent}`
                        : option.textContent,
                    customProperties: { icon: option.dataset.icon }
                });
            }
        });

        // Initialize Choices.js.
        new Choices(select, {
            allowHTML: true,
            searchEnabled: false,
            itemSelectText: '',
            choices: choicesData
        });
    });
});
